import React from 'react'
import * as qS from 'query-string'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import {Icon, Spin, Tag} from 'antd'
import AccountManagement from '../components/AccountManagement'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'
import PropType from 'prop-types'

class EmailManagementViewer extends React.Component{
    state = {
        email: null,
        username: null,
    }
    handleEmailAccountForParent = () => {
        this.setState({
            email: localStorage.getItem('email'),
            username: localStorage.getItem('username'),
        })
    }
    render(){
        let token = qS.parse(this.props.location.search).token ? qS.parse(this.props.location.search).token : ''
        return (
            <Layout>
                 <SEO title="Email" description="View a specific email."/>
                <main>
                    <div className="main-wrapper">
                        <AccountManagement
                        handleEmailAccountForParent={this.handleEmailAccountForParent.bind(this)}
                        />
                        <div className="inbox-management">
                            <Link to="/"><h1 className="title"><Icon type="arrow-left"/>Inbox</h1></Link>
                        </div>
                        {this.state.email &&
                            <FullEmail address={this.state.email} token={token}/>
                        }
                    </div>
                </main>
            </Layout>
        )
    }
}


const GET_EMAIL = gql`
    query Email($token: String!){
        email(token: $token){
            token,
            sender, 
            subject,
            timestamp,
            body_plain,
            body_html,
            recipients,
            attachments,
        }
    }
`

const FullEmail = ({address, token}) => (
    <Query 
        query={GET_EMAIL}
        variables={{address, token}}>
        {({loading, error, data}) => {
            if (loading) return (
                <div className="inbox-loading">
                    <Spin size="large"/>
                </div>
            )
        
            if (error) return (
                <div className="inbox-empty">
                    <Icon type="inbox" style={{ fontSize: '100px'}}/>
                    <h3>Error loading inbox :(</h3>
                </div>
            )
            if (!data.email) return (
                <div className="inbox-empty">
                    <Icon type="inbox" style={{ fontSize: '100px'}}/>
                    <h3>Email not found</h3>
                </div>
            )
            var {email} = data;
            return (
                <div className="email">
                    <div className="meta coloured">
                        <div className="from">{email.sender}</div>
                        <div className="subject">{email.subject}</div>
                        <div className="recipients">
                            Recipients: [{email.recipients.map(recipient => <Tag key={recipient}>{recipient}</Tag>)}]
                        </div>
                        {email.attachments.length > 0 &&
                        <div className="recipients">
                            Attachments: [
                                {email.attachments.map(attachment => 
                                    <Tag key={attachment}><a href={`https://s3.amazonaws.com/emaildrop-attachments/${email.token}/${attachment}`}>{attachment}</a></Tag>
                                )}
                            ]
                        </div>
                        }
                    </div>
                    <div className="body">
                        <div dangerouslySetInnerHTML={{ 
                            __html: data.email.body_html ? data.email.body_html : data.email.body_plain 
                        }}></div>
                    </div>
                </div>
            )
        }}
    </Query>
)

FullEmail.propType = {
    address: PropType.string,
    token: PropType.string
}

export default EmailManagementViewer;